import React, { useCallback, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { Col, Row, Card } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";

import UserSetupIcon from "assets/usersetup-icon.png";
import { AddNewAttributeBar, SelectAttributeSpan, StyledAttributeHeading, StyledHeadingSpan } from "./Attributes.style";

import { GET_ATTRIBUTES_IN_USE } from "api/attributes";
import { selectAttributes } from "./AttributesSlice";
import Loading from "components/Loading";
import messages from "../../api/messages";
import { attributeBoilerPlate, ATTRIBUTES_TYPES } from "./utils";
import { useHandleScrollToTop } from "hooks/dom.hooks";
import { UPDATE_ATTRIBUTE_ORDER } from "../../api/attributes";
import { StyledCardShadow, ResponsiveVerticalContainer } from "theme/StyledComponents";
import { reduceIds } from "utils/list.utils";
import WholeNumberForm from "../../components/Forms/WholeNumberForm";
import ValueListForm from "../../components/Forms/ValueListForm";
import ConnectionForm from "../../components/Forms/ConnectionForm";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";
import { useAttributes } from "hooks/application";
import { usePreviousValue } from "hooks/api.hooks";

const AttributeForms = {
  CONNECTION: ConnectionForm,
  WHOLE_NUMBER: WholeNumberForm,
  VALUE_LIST: ValueListForm
};

const Attributes = () => {
  const [dirtyForms, setDirtyForms] = useState(new Set());
  const [updateAttributeOrder] = useMutation(UPDATE_ATTRIBUTE_ORDER);
  const connectionsFeatureEnabled = useFeatureFlag(FEATURE_FLAGS.CONNECTIONS);
  const { loading, refetch } = useAttributes();

  const { projectData } = useSelector((state) => state.userData);

  const attributes = useSelector(selectAttributes);
  const [getAttrsInUse, { data: attrCheck }] = useLazyQuery(GET_ATTRIBUTES_IN_USE, {
    variables: {
      projectId: projectData?.id
    },
    skip: !projectData?.id,
    fetchPolicy: "no-cache"
  });
  useEffect(() => {
    if (projectData?.id) {
      getAttrsInUse();
    }
    // eslint-disable-next-line
  }, [projectData?.id]);

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      attributes
    }
  });
  const { fields, prepend, remove, move } = useFieldArray({
    control,
    name: "attributes"
  });
  const previousProjectId = usePreviousValue(attributes?.[0]?.projectId);
  useEffect(() => {
    // this hook resets attribute forms when a new value from attributes is returned from the api
    // setting keepValues to 'true' if the project has not changed to maintain form state - otherwise any dirty forms would be reset
    reset({ attributes }, { keepValues: previousProjectId === attributes[0]?.projectId });
  }, [attributes, reset, previousProjectId]);

  const saveAttributeOrder = useCallback(() => {
    const list = reduceIds(getValues().attributes);
    updateAttributeOrder({
      variables: { data: { list, projectId: projectData?.id } },
      skip: !projectData?.id,
      fetchPolicy: "no-cache"
    }).then(() => {
      refetch().then(async (res) => {
        setValue("attributes", res.data.attributes);
      });
    });
  }, [projectData?.id]);

  const handleOnDragEnd = async ({ destination, source }) => {
    move(source.index, destination.index);
    saveAttributeOrder();
  };

  const handleScrollToTop = useHandleScrollToTop("attributesContainer");

  const handleAddAttribute = (projectId, type) => () => {
    handleScrollToTop();
    prepend(attributeBoilerPlate(projectId, type));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Prompt message={messages.UNSAVED_CHANGES} when={dirtyForms.size > 0} />
      <StyledAttributeHeading className="d-lg-none d-xl-none d-md-none">
        <h3>Attributes</h3>
        <img style={{ height: "50px", marginLeft: "1rem" }} alt="" src={UserSetupIcon} />
      </StyledAttributeHeading>
      <Row className="" id="attributesContainer" style={{ maxHeight: "5rem" }}>
        <Col md={8} className="offset-md-2">
          <AddNewAttributeBar>
            <StyledHeadingSpan>Add a new attribute:</StyledHeadingSpan>
            <SelectAttributeSpan
              data-testid="NEW_WHOLE_NUMBER"
              onClick={handleAddAttribute(projectData?.id, ATTRIBUTES_TYPES.WHOLE_NUMBER)}
            >
              Whole Number
            </SelectAttributeSpan>
            <SelectAttributeSpan
              data-testid="NEW_VALUE_LIST"
              onClick={handleAddAttribute(projectData?.id, ATTRIBUTES_TYPES.VALUE_LIST)}
            >
              Value List
            </SelectAttributeSpan>
            <FeatureFlag featureName={FEATURE_FLAGS.CONNECTIONS}>
              <SelectAttributeSpan
                data-testid="NEW_CONNECTION"
                onClick={handleAddAttribute(projectData?.id, ATTRIBUTES_TYPES.CONNECTION)}
              >
                Connection
              </SelectAttributeSpan>
            </FeatureFlag>
          </AddNewAttributeBar>
        </Col>
      </Row>
      <ResponsiveVerticalContainer>
        <StyledCardShadow>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="attributes">
              {(provided) => (
                <span ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                  {fields.length > 0 &&
                    fields.map((attribute, i) => {
                      const attributeId = getValues().attributes[i].id;
                      const shouldShowDeleteModal =
                        Array.isArray(attrCheck?.attributesInUse) && attrCheck?.attributesInUse.includes(attributeId);
                      const AttributeForm = AttributeForms[attribute.type];
                      const showCard =
                        attribute.type === ATTRIBUTES_TYPES.CONNECTION ? connectionsFeatureEnabled : true;

                      if (!showCard) {
                        return null;
                      }

                      return (
                        <Draggable draggableId={attribute.id} key={attribute.id} index={i}>
                          {(prv) => (
                            <span
                              {...prv.draggableProps}
                              {...prv.dragHandleProps}
                              ref={prv.innerRef}
                              className="attributes"
                            >
                              <Card
                                key={attribute.id}
                                className="d-flex shadow-sm mb-3 flex-row align-items-center gap-2 pe-1 py-4"
                              >
                                <AttributeForm
                                  refetchAttributes={refetch}
                                  attribute={attribute}
                                  index={i}
                                  shouldShowDeleteModal={shouldShowDeleteModal}
                                  onSubmitSuccess={saveAttributeOrder}
                                  attributesInUse={attrCheck?.attributesInUse}
                                  attributeId={attributeId}
                                  setDirtyForms={setDirtyForms}
                                  parentForm={{
                                    remove,
                                    getValues
                                  }}
                                />
                              </Card>
                            </span>
                          )}
                        </Draggable>
                      );
                    })}
                </span>
              )}
            </Droppable>
          </DragDropContext>
        </StyledCardShadow>
      </ResponsiveVerticalContainer>
    </>
  );
};

export default React.memo(Attributes);
