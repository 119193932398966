import { map, orderBy, partition, filter } from "lodash";
import { ApproveOrReject } from "./UserActionTable";
import { OverflowTooltip } from "components/OverflowTooltip";
import { formatDateForDisplay } from "../../utils/date.utils";

export const mapUserActionsToFields = (action) => {
  return {
    createdAt: formatDateForDisplay(action?.createdAt),
    actionDate: formatDateForDisplay(action?.actionDate),
    name: action.name,
    creator: action.creator,
    result: `${action?.attribute?.name ?? action?.attributeName} ${action.operator.value} ${action.displayValue}`,
    value: action.calculatedValue,
    attributeName: action?.attribute?.name ?? action?.attributeName,
    approvalStatus: action.approvalStatus,
    approvalDetails: action.approvalDetails,
    customActionId: action?.customActionId,
    applyStrategy: action?.customAction?.applyStrategy,
    id: action.id,
    og_createdAt: action.createdAt,
    dateApplied: formatDateForDisplay(action?.dateApplied),
    calculatedValue: action.calculatedValue,
    limit: action?.customAction?.limit,
    expires: !!action.expirationDate,
    expired: action.expired,
    expiredDate: formatDateForDisplay(action?.expirationDate),
    operator: action.operator,
    displayValue: action.displayValue,
  };
};

// TODO Users/helper.js:UserActionsColumns, UserDashboard/helper.js:actionLogColumns, and Insights/helper.js:allUsersActionsLogColumns should be combined.
export const userActionsColumns = (handleApproveOrRejectSuccess, allowActions) => [
  {
    field: "dateApplied",
    headerName: "Date Applied",
    flex: 1,
    headerAlign: "left",
    cellClassName: "text-start"
  },
  { field: "creator", headerName: "Created By", cellClassName: "text-start", flex: 1.5 },
  {
    field: "name",
    headerName: "Action",
    flex: 2,
    renderCell: (params) => (
      <div className="d-flex flex-column" data-testid="ACTION_ROW_USERS_ACTION_LOG">
        <span className="MuiDataGrid-cellContent text-start w-100" style={{ maxWidth: "15rem" }}>
          <OverflowTooltip text={params.row.name} target={`action-name-${params.row.id}`} />
        </span>
        {params.row.approvalDetails && (
          <span className="text-start w-100" style={{ maxWidth: "30rem" }}>
            <OverflowTooltip text={`* ${params.row.approvalDetails}`} target={`approval-details-${params.row.id}`} />
          </span>
        )}
        {params.row.expires && !params.row.expired && (
          <span className="text-start expires">Expires {params.row.expiredDate}</span>
        )}
        {params.row.expires && params.row.expired && (
          <span className="text-start" style={{ color: "red" }}>
            Expired {params.row.expiredDate}
          </span>
        )}
      </div>
    )
  },
  {
    field: "actionDate",
    headerName: "Action Date",
    type: "date",
    flex: 1,
    cellClassName: "text-start",
    headerAlign: "left"
  },

  {
    field: "result",
    headerName: "Result",
    type: "string",
    flex: 1.5,
    cellClassName: "text-start",
    headerAlign: "left",
    renderCell: (params) => {
      let value = `${params.row.attributeName} ${params.row.operator} ${params.row.displayValue}`;

      if (params.row.approvalStatus === "APPROVED") {
        return value;
      }

      // TODO use an enum for approval status
      switch (params.row.approvalStatus) {
        case "REJECTED":
          value = "REJECTED";
          break;
        case "PENDING":
          value = "Awaiting Approval";
          break;
        case "LIMIT_EXCEEDED_TIMEFRAME":
          value = "Limit Exceeded";
          break;
        case "EXPIRED_NOT_APPROVED":
          value = "Expired: Not Approved"
          break;
      }
      return (
          <strong className="MuiDataGrid-cellContent" style={{ color: "red" }}>
            { value }
          </strong>
      )
    }
  },
  {
    field: "calculatedValue",
    headerName: "Value",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      if (params.row.approvalStatus === "APPROVED") {
        return params.row.calculatedValue;
      }

      if (params.row.approvalStatus === "PENDING" && allowActions) {
        return (
            <ApproveOrReject
                actionId={params.row.id}
                approvalDetails={params.row.approvalDetails}
                onSuccess={handleApproveOrRejectSuccess}
                approvalStatus={params.row.approvalStatus}
                allowApprovals={true}
                customActionId={params.row?.customActionId}
                limit={params.row?.limit}
            />
        );
      }

      // Other statuses show nothing.
      return "";
    }
  }
];

export const NEVER_LOGGED_IN = 'Never Logged In'

export const getParticipantTableData = (actions) => {
  const ordered = orderBy(actions, ({ dateApplied, actionDate }) => dateApplied || actionDate || "", ["desc", "desc"]);
  const mapped = map(ordered, mapUserActionsToFields);
  // * don't show pending stateful actions in table
  const filtered = filter(mapped, (a) => !(a.applyStrategy === "STATEFUL" && a.approvalStatus === "PENDING"));

  const [needsApproval, rest] = partition(filtered, {
    approvalStatus: "PENDING"
  });

  return [...needsApproval, ...rest];
};
