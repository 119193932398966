import React, { useEffect, useCallback, memo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { find, omit } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";
import messages from "../../api/messages";
import { CREATE_CUSTOM_ACTION } from "api/actions";

// * Components
import ActionExpirationForm from "./ActionExpirationForm";
import { ActionFormButton, FormErrorText } from "theme/StyledComponents";
import AutoAddUsersForm from "./AutoAddUsersForm";
import CustomCheckbox from "components/CustomCheckbox";
import CustomSelect from "components/CustomSelect";
import { FeatureFlag } from "../../hocs/FeatureFlag/FeatureFlag";
import { UncontrolledInput } from "components/CustomInput/CustomInput";

// * Slices
import { selectNonConnectionAttributes } from "features/Attributes/AttributesSlice";
import { selectProjectData } from "../../features/userData/userDataSlice";
import { selectSpecificOperators } from "app/operators.slice";

// * Utils/Hooks
import { ACTION_TYPES, EVENT_OPTIONS, TEAMS_EVENT_OPTIONS, useActionOptions } from "../../features/Actions/utils";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { FEATURE_FLAGS } from "../../hocs/FeatureFlag/utils";
import { inputValidation } from "../../utils/form.utils";
import { useHasDirtyForm, useSelectedAttribute, useSetConditionValue, useSubmit } from "../../hooks/form.hooks";

const Form = styled.form`
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

const ButtonsContainer = styled.div`
  margin-left: auto;
  width: 9rem;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 0;
`;

const FormRow = styled.div.attrs({ className: "d-flex flex-row justify-content-start w-100 gap-2 align-items-center" })`
  max-width: 150%;
  margin-top: 1rem;
  & > * {
    margin-bottom: 0.5rem;
  }
`;

let triggerCount = 0;

function CustomActionForm({
  handleClearForm = () => ({}),
  handleDelete = () => ({}),
  handleSaveSuccess,
  setDirtyForms,
  actionId
}) {
  const { slackChannels, msTeamsChannels } = useSelector((state) => state.integrations);

  const attributes = useSelector(selectNonConnectionAttributes);
  const operators = useSelector(selectSpecificOperators(["+", "-", "="]));

  const actionOptions = useActionOptions(slackChannels, msTeamsChannels);
  const trackingOptions = [
    { id: "INSTANCE", name: "Instance" },
    { id: "STATEFUL", name: "State" }
  ];

  const limitOptions = [
    { id: "NONE", name: "No Limit" },
    { id: "HOUR", name: "Hour" },
    { id: "DAY", name: "Day" },
    { id: "WEEK", name: "Week" },
    { id: "MONTH", name: "Month" },
    { id: "YEAR", name: "Year" },
    { id: "EVER", name: "All Time" }
  ];

  const [defaultValues, setDefaultValues] = useState({
    approvalRequired: false,
    autoAddUsers: false,
    autoAddUsersRole: "observed_only",
    applyStrategy: "INSTANCE",
    type: actionOptions[0].id,
    operatorId: operators[0].id,
    attributeId: attributes[0].id,
    incomingEventType: EVENT_OPTIONS[0].id,
    limit: limitOptions[0].id,
    value: attributes[0].value?.id,
    attributeValue: attributes.find((att) => att.type === "VALUE_LIST")?.attributeValues[0].id,
    name: ""
  });

  const {
    control,
    setValue,
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isValid, dirtyFields, isDirty, errors },
    getValues,
    trigger
  } = useForm({
    mode: "onSubmit",
    defaultValues,
    resetOptions: { keepDefaultValues: true }
  });

  const attributeId = watch("attributeId");
  const autoAddUsers = watch("autoAddUsers");
  const autoAddUsersRole = watch("autoAddUsersRole");
  const approvalRequired = watch("approvalRequired");
  const applyStrategy = watch("applyStrategy");
  const channelId = watch("channelId");
  const source = watch("type", actionOptions[0].id);
  const operatorId = watch("operatorId");
  const value = watch("value");
  const attributeValue = watch("attributeValue");
  const name = watch("name");

  const selectedAttribute = useSelectedAttribute(attributeId, true);

  const [createCustomAction, { loading: saving }] = useMutation(CREATE_CUSTOM_ACTION);
  const projectData = useSelector(selectProjectData);
  const [plusOperator, equalsOperator] = useSelector(selectSpecificOperators(["+", "="]));

  const processSlackActionForm = (formData) => {
    const channelName = find(slackChannels, (c) => c.id === formData.channelId).name;
    const foundEvent = EVENT_OPTIONS.find((opt) => opt.id === formData.incomingEventType)?.name;
    const payload = {
      ...omit(formData, ["attributeValue"]),
      channelName,
      name: `Slack ${foundEvent} #${channelName}`,
      approvalRequired: false,
      value: selectedAttribute?.type === "WHOLE_NUMBER" ? value : attributeValue,
      operatorId: selectedAttribute?.type === "WHOLE_NUMBER" ? operatorId : equalsOperator.id
    };

    return payload;
  };

  const processTeamsActionForm = (formData) => {
    const channelName = find(msTeamsChannels, (c) => c.id === formData.channelId).name;
    const foundEvent = TEAMS_EVENT_OPTIONS.find((opt) => opt.id === formData.incomingEventType)?.name;
    const payload = {
      ...omit(formData, ["attributeValue"]),
      channelName,
      name: `MS Teams ${foundEvent} #${channelName}`,
      approvalRequired: false,
      value: selectedAttribute?.type === "WHOLE_NUMBER" ? value : attributeValue,
      operatorId: selectedAttribute?.type === "WHOLE_NUMBER" ? operatorId : equalsOperator.id
    };

    return payload;
  };

  const processCustomActionForm = (formData) => {
    const payload = {
      ...omit(formData, ["attributeValue"]),
      incomingEventType: null,
      channelId: null,
      channelName: null,
      autoAddUsersRole,
      value: selectedAttribute?.type === "WHOLE_NUMBER" ? value : attributeValue,
      operatorId: selectedAttribute?.type === "WHOLE_NUMBER" ? operatorId : equalsOperator.id
    };
    return payload;
  };

  const extractActionFormData = (formData) => {
    switch (source) {
      case ACTION_TYPES.SLACK:
        return processSlackActionForm(formData);
      case ACTION_TYPES.TEAMS:
        return processTeamsActionForm(formData);
      default:
        return processCustomActionForm(formData);
    }
  };

  const handleUpdateSuccess = (action) => {
    reset(action);
  };

  const setExpirationValues = (values) => {
    if (values.expires && applyStrategy !== "STATEFUL") {
      setValue("expirationPeriodAmount", +values.periodAmount);
      setValue("expirationPeriod", values.period);
    } else {
      setValue("expirationPeriodAmount", null);
      setValue("expirationPeriod", null);
    }
  };

  const onSubmit = useSubmit({
    mutation: createCustomAction,
    successMessage: messages.CUSTOM_ACTION_SAVE_SUCCESS,
    errorMessage: messages.CUSTOM_ACTION_SAVE_ERROR,
    clearForm: handleClearForm,
    dataPath: CREATE_CUSTOM_ACTION.definitions[0].name.value,
    onSuccess: !!handleSaveSuccess ? handleSaveSuccess : handleUpdateSuccess,
    extractFormData: (formData) => {
      return {
        ...extractActionFormData(formData),
        id: actionId,
        projectId: projectData?.id
      };
    }
  });

  // useIntendedInitialOperator(operatorId, selectedAttribute?.type, setValue);
  useHasDirtyForm(Object.keys(dirtyFields).length > 0, actionId, setDirtyForms);

  const isMessagingIntegrationAction = source === ACTION_TYPES.TEAMS || source === ACTION_TYPES.SLACK;

  useEffect(() => {
    reset();
  }, [reset, actionOptions.length]);

  useEffect(() => {
    if (isMessagingIntegrationAction) {
      setValue("applyStrategy", "INSTANCE", { shouldDirty: true, shouldValidate: true });
    }
  }, [setValue, isMessagingIntegrationAction]);

  useSetConditionValue(
    selectedAttribute,
    setValue,
    selectedAttribute?.type === "VALUE_LIST" ? "attributeValue" : "value",
    selectedAttribute?.type === "VALUE_LIST" ? getValues().attributeValue : getValues().value
  );

  // * CustomSelect requires defaultValues set on form so when form changes to Teams or Slack ensure values
  useEffect(() => {
    if (source === ACTION_TYPES.TEAMS) {
      setValue("channelId", msTeamsChannels[0].id, { shouldDirty: true, shouldValidate: true });
      setValue("incomingEventType", EVENT_OPTIONS[0].id, { shouldDirty: true, shouldValidate: true });
    } else if (source === ACTION_TYPES.SLACK) {
      setValue("channelId", slackChannels[0].id, { shouldDirty: true, shouldValidate: true });
      setValue("incomingEventType", EVENT_OPTIONS[0].id, { shouldDirty: true, shouldValidate: true });
    }
  }, [source, setValue, ACTION_TYPES]);

  const displayEventName = useCallback(
    (_action) => {
      switch (source) {
        case ACTION_TYPES.TEAMS:
          return (
            <CustomSelect
              label="ACTION"
              formRegister={register}
              name="incomingEventType"
              options={TEAMS_EVENT_OPTIONS}
              displayKey="name"
              width="9rem"
              defaultValue={
                EVENT_OPTIONS.find((option) => option.id === getValues("incomingEventType")) ?? EVENT_OPTIONS[0]
              }
              validation={inputValidation}
              control={control}
            />
          );
        case ACTION_TYPES.SLACK:
          return (
            <CustomSelect
              label="ACTION"
              formRegister={register}
              name="incomingEventType"
              options={EVENT_OPTIONS}
              displayKey="name"
              width="9rem"
              validation={inputValidation}
              defaultValue={
                EVENT_OPTIONS.find((option) => option.id === getValues("incomingEventType")) ?? EVENT_OPTIONS[0]
              }
              control={control}
            />
          );
        default:
          return (
            <UncontrolledInput
              labelText="NAME"
              formRegister={register}
              name="name"
              validation={inputValidation}
              width="13rem"
              defaultValue={getValues().name}
              className={errors?.name ? "error" : ""}
              testId="actionName"
            />
          );
      }
    },
    [source, register, source, errors]
  );

  if (!selectedAttribute) {
    return null;
  }

  const handleSelectedRole = (role) => {
    setValue("autoAddUsersRole", role, { shouldDirty: true, shouldTouch: true });
  };

  const handleSelectedAutoAddValue = (checked) => {
    setValue("autoAddUsers", checked, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <>
      {defaultValues !== null && (
        <Form onSubmit={handleSubmit(onSubmit)} data-testid="CUSTOM_ACTION_FORM">
          <FormRow>
            <CustomSelect
              label="SOURCE"
              control={control}
              name="type"
              options={actionOptions}
              width="10"
              maxWidth="10"
              defaultValue={actionOptions?.find((option) => option.id === source)}
              formRegister={register}
              extraWidth={true}
            />
            {displayEventName(getValues())}
            {source === ACTION_TYPES.SLACK && (
              <CustomSelect
                label="CHANNEL"
                formRegister={register}
                name="channelId"
                options={slackChannels}
                displayKey="name"
                width="10rem"
                defaultValue={slackChannels?.find((channel) => channel.id === channelId)}
                validation={inputValidation}
                control={control}
              />
            )}
            {source === ACTION_TYPES.TEAMS && (
              <CustomSelect
                label="CHANNEL"
                formRegister={register}
                name="channelId"
                options={msTeamsChannels}
                displayKey="name"
                width="10rem"
                defaultValue={msTeamsChannels?.find((channel) => channel.id === channelId) ?? msTeamsChannels[0]}
                validation={inputValidation}
                control={control}
              />
            )}
            <strong className="mx-2">=</strong>
            <CustomSelect
              label="ATTRIBUTE"
              formRegister={register}
              name="attributeId"
              options={attributes}
              displayKey="name"
              defaultValue={attributes.find((att) => att.id === attributeId) ?? attributes[0]}
              validation={inputValidation}
              width="10rem"
              control={control}
            />
            {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
              <CustomSelect
                label="OPERATOR"
                formRegister={register}
                name="operatorId"
                width="4.5rem"
                defaultValue={operators.find((op) => op.id === getValues("operatorId")) ?? plusOperator}
                options={operators}
                validation={inputValidation}
                control={control}
              />
            ) : (
              <strong>=</strong>
            )}
            {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER && (
              <>
                <UncontrolledInput
                  labelText="VALUE"
                  formRegister={register}
                  name="value"
                  width="8.5rem"
                  type="number"
                  validation={inputValidation}
                  defaultValue={getValues()?.value}
                  testId="VALUE"
                  className={errors.value?.type === "required" ? "error" : ""}
                />
              </>
            )}
            {selectedAttribute?.type === ATTRIBUTES_TYPES.VALUE_LIST && (
              <CustomSelect
                label="VALUE"
                formRegister={register}
                name="attributeValue"
                width="10rem"
                displayKey="name"
                options={selectedAttribute?.attributeValues}
                defaultValue={
                  selectedAttribute?.attributeValues?.find((av) => av.id === attributeValue) ??
                  selectedAttribute?.attributeValues[0]
                }
                validation={inputValidation}
                control={control}
              />
            )}
          </FormRow>
          <ButtonsContainer>
            <ActionFormButton
              data-testid="SUBMIT_ACTION"
              type="submit"
              className="btn btn-outline-success shadow border-0"
              disabled={!isDirty || saving}
            >
              <i className="fa fa-check" />
            </ActionFormButton>
            <ActionFormButton
              type="button"
              className="btn shadow border-0 btn-outline-primary"
              buttonType="undo"
              onClick={() =>
                reset(
                  {
                    ...defaultValues
                  },
                  {
                    keepDefaultValues: true,
                    keepIsValid: false
                  }
                )
              }
              disabled={!isDirty || saving}
            >
              <i className="fa fa-undo" />
            </ActionFormButton>
            <ActionFormButton
              type="button"
              className="btn border-0 shadow btn-outline-danger"
              onClick={handleDelete}
              buttonType="delete"
              disabled={saving}
            >
              <i className="fa fa-times" />
            </ActionFormButton>
          </ButtonsContainer>
          <FormRow>
            {!isMessagingIntegrationAction && source !== ACTION_TYPES.MAGIC_LINK && (
              <CustomSelect
                label="TRACK PER"
                formRegister={register}
                name="applyStrategy"
                width="8rem"
                displayKey="name"
                options={trackingOptions}
                defaultValue={trackingOptions.find((opt) => opt.id === getValues("applyStrategy"))}
                validation={inputValidation}
                control={control}
              />
            )}
            {applyStrategy !== "STATEFUL" && (
              <CustomSelect
                label="LIMIT 1 PER"
                formRegister={register}
                name="limit"
                width="7.5rem"
                displayKey="name"
                options={limitOptions}
                defaultValue={limitOptions.find((limit) => limit.id === getValues("limit"))}
                validation={inputValidation}
                control={control}
              />
            )}
            <FeatureFlag featureName={FEATURE_FLAGS.EXPIRING_ACTIONS}>
              {applyStrategy !== "STATEFUL" && (
                <div className="ms-5">
                  <ActionExpirationForm emitExpiration={setExpirationValues} />
                </div>
              )}
            </FeatureFlag>
          </FormRow>
          <FormRow>
            {source === ACTION_TYPES.MANUAL_ENTRY_PARTICIPANT && (
              <div className="d-flex flex-row">
                <CustomCheckbox
                  formRegister={register}
                  name="approvalRequired"
                  checked={approvalRequired ?? false}
                  inputStyle={{ display: "flex", justifyContent: "center" }}
                  testId="approvalRequired"
                  className="m-auto"
                />
                <div className="ms-2 mt-1">Needs Approval</div>
              </div>
            )}
            {source !== "MAGIC_LINK" && (
              <div className={source === ACTION_TYPES.MANUAL_ENTRY_PARTICIPANT ? "ms-5" : ""}>
                <AutoAddUsersForm
                  register={register}
                  autoAddUsers={autoAddUsers}
                  emitSelectedRole={handleSelectedRole}
                  emitAutoAddUsers={handleSelectedAutoAddValue}
                  role={autoAddUsersRole}
                />
              </div>
            )}
          </FormRow>
        </Form>
      )}
    </>
  );
}

CustomActionForm.propTypes = {
  handleClearForm: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSaveSuccess: PropTypes.func,
  action: PropTypes.object,
  setDirtyForms: PropTypes.func,
  actionId: PropTypes.string
};

CustomActionForm.defaultProps = {
  handleClearForm: () => ({}),
  handleDelete: () => ({}),
  action: {}
};

export default CustomActionForm;
