import { memo, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Modal, ModalBody, Row, UncontrolledTooltip } from "reactstrap";
import { debounce, filter, map, toLower, omit } from "lodash";

// * Components
import { ActionFormButton, StyledCardShadow } from "theme/StyledComponents";
import BulkLogActionForm from "./Forms/BulkLogActionForm";
import ConfirmationModal from "./ConfirmationModal";
import CustomCheckbox from "./CustomCheckbox";
import MultipleEmailInput from "./MultipleEmailInput/MultipleEmailInput";
import { UncontrolledInput } from "components/CustomInput/CustomInput";

// * Helpers, Selectors, Utils
import { EMAIL_STRING_SPLIT } from "./MultipleEmailInput/helper";
import { meetsUsersFilter } from "../utils/users.utils";
import messages from "../api/messages";
import { selectAccountData } from "features/userData/userDataSlice";
import { selectNonConnectionAttributes } from "../features/Attributes/AttributesSlice";
import { useSubmit } from "../hooks/form.hooks";
import { selectUsers } from "../app/project.slice";
import { selectInstanceActions } from "../app/actions.slice";

// * Mutations + Apollo Hooks
import { CREATE_BULK_LOG_ACTION } from "../api/actions";
import { useGetUserAndActions } from "../hooks/application";
import { useMutation } from "@apollo/client";
import RoleSelectionForNewUsers from "./Forms/RoleSelectionForNewUsers";

// * BulkLogActionModal Component provides a container for Bulk Log Action inputs and handles create mutation of BulkLogAction
const BulkLogActionModal = ({
  toggle,
  emailsInUse = [],
  projectId,
  queries,
  handleApproveOrRejectSuccess = () => ({})
}) => {
  // * Modal body methods
  const [showConfirm, setShowConfirm] = useState(false);
  const toggleConfirm = () => setShowConfirm(!showConfirm);
  const [showWarningClose, setShowWarningClose] = useState(false);
  const [alreadyExpired, setAlreadyExpired] = useState(false);
  const [emailsLengthError, setEmailsLengthError] = useState(false);
  const toggleWarningClose = () => setShowWarningClose(!showWarningClose);
  const handleWarningClose = () => {
    toggleWarningClose();
    toggle();
  };

  // * Selectors
  const customActions = useSelector(selectInstanceActions);
  const currentAccount = useSelector(selectAccountData);
  const nonConnectionAttributes = useSelector(selectNonConnectionAttributes);
  const users = useSelector(selectUsers);
  const { refetch: refetchUser } = useGetUserAndActions();
  const actionOptions = useMemo(
    () => [
      {
        id: "MANUAL_ENTRY",
        name: "Manual Entry",
        value: "",
        operator: { id: "" },
        attribute: { id: "" },
        type: "MANUAL_ENTRY",
        customActionId: "MANUAL_ENTRY"
      },
      ...customActions
    ],
    [customActions]
  );
  // * Mutations
  const [createBulkLogAction, { loading: actionLoading }] = useMutation(CREATE_BULK_LOG_ACTION);

  // * Action Form prop data
  const [resetActionForm, setResetActionForm] = useState(false);
  const [actionIsValid, setActionIsValid] = useState(false);
  const [selectedAction, setSelectedAction] = useState({ action: actionOptions[0] });
  const [selectedRole, setSelectedRole] = useState("user");
  const [calculatedExpirationDate, setCalculatedExpirationDate] = useState(null);
  const [roleSelectDisabled, setRoleSelectDisabled] = useState(true);

  const handleReset = () => {
    setResetActionForm(true);
    setRoleSelectDisabled(true);
    reset();
  };

  const handleBulkLogActionFormReset = () => {
    setResetActionForm(false);
  };

  // * The Form
  const { reset, register, watch, getValues, setValue } = useForm({
    defaultValues: {
      searchTerm: "",
      users: users.map((user) => ({ ...user, selected: false })),
      emailInput: "",
      emailInputErrors: 0,
      emailDomainErrors: 0,
      selectAllUsers: false,
      expires: false
    }
  });

  // * Initialize internal watch properties for form
  const _users = watch("users");
  const searchTerm = watch("searchTerm");
  const emailInput = watch("emailInput");
  const emailErrors = watch("emailInputErrors");
  const emailDomainErrors = watch("emailDomainErrors");
  const selectAllUsers = watch("selectAllUsers");

  // * Users and Manually Entered Emails
  const handleSelectedUsersReset = () => {
    setValue(
      "users",
      _users.map((user) => ({ ...user, selected: false }))
    );
    setValue("selectAllUsers", false);
  };

  const handleSelectAllUsers = () => {
    setValue(
      "users",
      _users.map((user) => ({ ...user, selected: true }))
    );
    setValue("selectAllUsers", true);
  };

  useEffect(() => {
    if (selectAllUsers) {
      handleSelectAllUsers();
    } else {
      handleSelectedUsersReset();
    }
  }, [selectAllUsers]);

  useEffect(() => {
    if (!!emailInput) {
      setRoleSelectDisabled(false);
    }
  }, [emailInput, setRoleSelectDisabled]);

  // * Creates userId[] and email[] for mutation payload
  const extractUsersData = () => {
    const userIds = _users.reduce((acc, curr) => {
      if (curr.selected) {
        acc.push(curr.id);
      }
      return acc;
    }, []);

    const emails = map(
      filter(emailInput.split(EMAIL_STRING_SPLIT), (e) => !!e),
      toLower
    );

    return { userIds, manualEntryEmails: emails };
  };

  useEffect(() => {
    const emails = map(
      filter(emailInput.split(EMAIL_STRING_SPLIT), (e) => !!e),
      toLower
    );
    if (emails.length > 20) {
      setEmailsLengthError(true);
    }

    if (emails.length <= 20 && emailsLengthError) {
      setEmailsLengthError(false);
    }
  }, [emailInput, emailsLengthError]);

  // * enableReset determines state of reset button for entire modal
  const enableReset =
    !!selectedAction && (extractUsersData().userIds.length > 0 || extractUsersData().manualEntryEmails.length > 0);

  // * Submittable determines state of submit button for entire modal
  const submittable = actionIsValid && enableReset && !emailDomainErrors && !emailErrors;

  const extractFormValuesFromAction = (payload) => {
    const expires = payload?.action?.expires;
    const action = {
      ...omit(payload.action, ["expires", "expirationPeriodAmount", "expirationPeriod"]),
      value: payload.action?.value?.toString(),
      projectId: projectId,
      userId: currentAccount.id,
      expired: false,
      expirationDate: expires ? calculatedExpirationDate : null
    };
    return action;
  };

  // * Handle Submit - pass payload to createBulkLogAction mutation
  const handleSubmit = useSubmit({
    mutation: createBulkLogAction,
    variables: {
      data: {
        projectId,
        action: {
          ...extractFormValuesFromAction(selectedAction)
        },
        role: selectedRole ?? "user",
        manualEntryEmails: extractUsersData().manualEntryEmails ?? [],
        userIds: extractUsersData().userIds ?? []
      }
    },
    useVariables: true,
    dataPath: CREATE_BULK_LOG_ACTION.definitions[0].name.value,
    useGlobalLoading: true,
    onSuccess: async () => {
      queries.refetchProjectUsers();
      refetchUser().catch();
      handleReset();
      toggle();
    },
    onFail: (e) => {
      // TODO - frontend error handling in a better way / is this even necessary?
      console.log("BULK LOG FAILURE:::", e);
    }
  });

  // * Dynamic confirmation and warning modal text
  const generateConfirmationModalText = () => {
    if (!alreadyExpired) {
      return `Are you ready to log the chosen action for the ${
        extractUsersData().userIds.length > 0 ? "selected" : ""
      } ${
        extractUsersData().userIds.length > 0 && extractUsersData().manualEntryEmails.length > 0 ? "and" : ""
      } ${extractUsersData().manualEntryEmails.length > 0 ? "listed" : ""} users?`;
    } else {
      return `WARNING!: ${messages.EXPIRED_ACTION_WARNING}`;
    }
  };

  const confirmationModalText = generateConfirmationModalText();
  const closeModalWarningText =
    "You have made bulk log action selections. Are you sure you want to close this without logging the action for the users?";

  const debouncedOnSubmit = debounce(handleSubmit, 1000, { leading: true });

  return (
    <>
      {showConfirm && (
        <ConfirmationModal
          type="confirm"
          toggle={toggleConfirm}
          handleSubmit={debouncedOnSubmit}
          bodyText={confirmationModalText}
          loading={actionLoading}
        />
      )}
      {showWarningClose && (
        <ConfirmationModal
          type="warning"
          toggle={toggleWarningClose}
          handleSubmit={handleWarningClose}
          bodyText={closeModalWarningText}
          loading={actionLoading}
        />
      )}
      <Modal isOpen toggle={toggle} size="xl" style={{ height: "92%" }}>
        <ModalBody className="text-center px-0 pb-3">
          <div
            style={{
              position: "relative",
              paddingTop: "1.5rem",
              paddingBottom: "1rem",
              borderBottom: "2px solid #0d6efd"
            }}
          >
            <h2 style={{ display: "inline" }}>Bulk Log Action</h2>
            <div
              className="d-inline-flex justify-content-around"
              style={{
                width: "20%",
                position: "absolute",
                top: "1.5rem",
                right: 0
              }}
            >
              <UncontrolledTooltip hideArrow placement="top" target="submit">
                Log action for users
              </UncontrolledTooltip>
              <UncontrolledTooltip hideArrow placement="top" target="reset">
                Reset
              </UncontrolledTooltip>
              <UncontrolledTooltip hideArrow placement="top" target="cancel">
                Cancel
              </UncontrolledTooltip>
              <UncontrolledTooltip hideArrow placement="top" target="clear-selections">
                Clear Selected
              </UncontrolledTooltip>
              <ActionFormButton
                id="submit"
                type="button"
                onClick={toggleConfirm}
                data-testid="BULK_LOG_ACTION_MODAL_SUBMIT"
                className="btn btn-outline-success shadow border-0"
                disabled={!submittable || actionLoading || emailsLengthError}
              >
                <i className="fa fa-check" />
              </ActionFormButton>

              <ActionFormButton
                type="button"
                className="btn shadow border-0 btn-outline-primary"
                buttonType="undo"
                data-testid="BULK_LOG_ACTION_MODAL_RESET"
                id="reset"
                onClick={handleReset}
                disabled={!enableReset || actionLoading}
              >
                <i className="fa fa-undo" />
              </ActionFormButton>

              <ActionFormButton
                id="cancel"
                onClick={() => (enableReset ? toggleWarningClose() : toggle())}
                className="btn shadow border-0 btn-outline-danger"
                data-testid="BULK_LOG_ACTION_MODAL_CLOSE"
              >
                <i className="fa fa-times" />
              </ActionFormButton>
            </div>
          </div>
          {/*ACTION FORM*/}
          <div
            className="d-flex flex-row"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              paddingTop: "1.5rem",
              paddingBottom: "1.5rem",
              borderBottom: "2px solid rgb(210, 210, 210)"
            }}
          >
            <BulkLogActionForm
              resetForm={resetActionForm}
              handleApproveOrRejectSuccess={handleApproveOrRejectSuccess}
              attributes={nonConnectionAttributes ?? []}
              user={currentAccount}
              emitSelectedAction={setSelectedAction}
              actionOptions={actionOptions}
              emitActionIsValid={setActionIsValid}
              emitFormReset={handleBulkLogActionFormReset}
              emitExpired={setAlreadyExpired}
              emitCalculatedExpirationDate={setCalculatedExpirationDate}
            />
          </div>

          {/*BODY*/}
          <div className="d-flex justify-items-around">
            <div
              className="pt-3 w-50"
              style={{
                paddingLeft: "2rem",
                paddingRight: "2rem"
              }}
            >
              <div>
                <h5>Select From Project</h5>
              </div>
              <div className="d-flex mb-1 flex-row justify-content-around align-items-center">
                <div style={{ width: "40%" }}>
                  <UncontrolledInput
                    name="searchTerm"
                    labelText="FILTER BY KEYWORD"
                    value={searchTerm}
                    formRegister={register}
                    testId="BULK_LOG_USERS_FILTER"
                  />
                </div>
                <div className="d-flex flex-row justify-content-around">
                  <CustomCheckbox
                    checked={selectAllUsers}
                    formRegister={register}
                    name={`selectAllUsers`}
                    testId="BULK_LOG_SELECT_ALL"
                  />
                  <div className="m-auto ms-2">Select All</div>
                </div>

                <div className="d-flex flex-row justify-content-around">
                  <ActionFormButton
                    type="button"
                    className="btn shadow border-0 btn-outline-primary"
                    buttonType="undo"
                    id="clear-selections"
                    data-testid="BULK_LOG_CLEAR_SELECTED_USERS"
                    onClick={handleSelectedUsersReset}
                    disabled={extractUsersData().userIds.length === 0}
                  >
                    <i className="fa fa-undo" />
                  </ActionFormButton>
                  <div className="m-auto ms-2">Clear Selected</div>
                </div>
              </div>
              <StyledCardShadow className="py-3" style={{ overflowY: "auto", height: "400px" }}>
                {_users.map((user, i) => {
                  if (meetsUsersFilter(user, searchTerm))
                    return (
                      <Card key={user.email} className="mb-2 shadow-sm">
                        <CardBody>
                          <Row>
                            <Col md={3} className="d-flex align-items-center justify-content-end">
                              <CustomCheckbox
                                checked={getValues(`users.${i}.selected`)}
                                name={`users.${i}.selected`}
                                formRegister={register}
                              />
                            </Col>
                            <Col className="text-start" md={9}>
                              {`${user.firstName}  ${user.lastName}`}
                              <div>Email: {user.email}</div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    );
                })}
              </StyledCardShadow>
            </div>

            <div
              className="d-flex justify-items-between flex-column w-50 mb-1 pt-3"
              style={{
                paddingRight: "2rem",
                paddingLeft: "2rem",
                borderLeft: "2px solid #d2d2d2"
              }}
            >
              <div>
                <h5>By Email</h5>
                {emailsLengthError && (
                  <span style={{ color: "red", fontSize: "1rem" }}>* Limit 20 email addresses.</span>
                )}
              </div>
              <div className="d-flex mb-1 flex-row pt-2 pb-2 m-auto">
                <RoleSelectionForNewUsers setRole={setSelectedRole} small={true} disabled={roleSelectDisabled} />
              </div>
              <div style={{ height: "400px", margin: "auto", width: "100%" }}>
                <MultipleEmailInput
                  emailsInUse={emailsInUse}
                  form={{ setValue, emailInput }}
                  account={currentAccount}
                  checkUserExists={true}
                />
                {emailErrors > 0 && (
                  <span style={{ color: "red", fontSize: "0.75rem", position: "relative", left: "30%" }}>
                    * Invalid email address
                  </span>
                )}
                {emailDomainErrors > 0 && (
                  <span className="mx-2" style={{ color: "red", fontSize: "0.75rem" }}>
                    * Email domain must be {currentAccount?.emailDomain}
                  </span>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default memo(BulkLogActionModal);
