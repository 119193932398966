import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useQuery } from "@apollo/client";
import { selectProjectData } from "../../features/userData/userDataSlice";
import { GET_CUSTOM_ACTIONS } from "../../api/actions";
import { selectCustomActions, setCustomActionList } from "../../app/actions.slice";

export const useCustomActions = () => {
  const dispatch = useDispatch();
  const actionList = useSelector(selectCustomActions);
  const projectData = useSelector(selectProjectData);

  const { data, loading, refetch } = useQuery(GET_CUSTOM_ACTIONS, {
    variables: { projectId: projectData?.id },
    skip: !projectData?.id
  });

  const stateAndApiEqual = isEqual(JSON.stringify(actionList), JSON.stringify(data?.getCustomActions));
  useEffect(() => {
    if (!stateAndApiEqual && Array.isArray(data?.getCustomActions)) {
      console.count("setting customActions");
      dispatch(setCustomActionList(data?.getCustomActions));
    }
  }, [dispatch, data?.getCustomActions]); // eslint-disable-line

  return { data, loading, refetch };
};
