import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { isEmpty, findIndex, find, map, filter } from "lodash";
import { useForm, useFieldArray } from "react-hook-form";

import UserList from "../Users/UserList";
import AttributeList from "../Users/AttributeList";
import UserActionTable from "../Users/UserActionTable";
import Loading from "components/Loading";
import { selectAccountData, setSelectedUser } from "features/userData/userDataSlice";
import { ResponsiveVerticalContainer } from "theme/StyledComponents";
import { GET_ACCOUNT_TEAMS } from "api/teams";
import { selectUserId } from "../../app/selectors/index";
import { mapTeamOptionsSelectOnly, NO_TEAM } from "utils/team.utils";
import { selectUserAccountAccess } from "../userData/userDataSlice";
import { selectUsers } from "app/project.slice";
import { selectNonConnectionAttributes } from "features/Attributes/AttributesSlice";
import { useAttributes, useProjectUsers, useResetSelectedUser, useGetUserAndActions } from "hooks/application";

function UsersInsightsView() {
  useResetSelectedUser();
  useGetUserAndActions();
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectAccountData);
  const participants = useSelector(selectUsers);
  const nonConnectionAttributes = useSelector(selectNonConnectionAttributes);

  const { loading: participantsLoading } = useProjectUsers();
  const { loading: attributesLoading } = useAttributes();
  const { permissions = ["user"] } = useSelector(selectUserAccountAccess) || {};
  const userId = useSelector(selectUserId);
  const [currentUser, setCurrentUser] = useState({});
  const { projectData, selectedUser } = useSelector((state) => state.userData);
  const { control, setValue, getValues } = useForm({
    defaultValues: {
      users: []
    }
  });

  useEffect(() => {
    if (participants?.length > 0) {
      setCurrentUser(find(participants, (user) => user.id === userId));
    }
  }, [participants, userId]);

  const currentUserTeam =
    find(currentUser?.teams, (team) => {
      return team.accountId === currentAccount?.id;
    }) ?? NO_TEAM;

  const { data: teamData, refetch: refetchTeams } = useQuery(GET_ACCOUNT_TEAMS, {
    variables: {
      accountId: currentAccount?.id
    },
    skip: !currentAccount?.id
  });

  const teamOptions = React.useMemo(
    () => mapTeamOptionsSelectOnly(teamData?.accountTeams ?? []),
    [teamData?.accountTeams]
  );

  const options = permissions.includes("team_observer") ? [currentUserTeam] : teamOptions;

  const teamContext = {
    refetchTeams,
    teamFilterOptions: options,
    initialTeamId: currentUserTeam?.id
  };

  const { fields, remove } = useFieldArray({
    control,
    name: "users"
  });

  useEffect(() => {
    // Todo(MB): fix be resolver to only return teams for current org
    setValue(
      "users",
      map(participants, (u) => ({
        ...u,
        teams: filter(u?.teams, (t) => t.accountId === currentAccount?.id)
      }))
    );
  }, [participants, setValue, currentAccount?.id]);

  // -----------------------------------
  // selectedUser Updates
  // -----------------------------------
  // set null when projectId changes so that it can be set to the first user by the next hook
  useEffect(() => {
    dispatch(setSelectedUser(null));
  }, [dispatch, projectData?.id]);

  // set to first in list when new participants
  useEffect(() => {
    if (!isEmpty(participants) && !selectedUser?.id) {
      const team = filter(participants, (user) => user?.teams?.[0]?.id === teamContext?.initialTeamId);
      dispatch(setSelectedUser(team[0]));
    }
  }, [participants, dispatch, selectedUser?.id, teamContext?.initialTeamId]);
  // -----------------------------------
  // END selectedUser Updates
  // -----------------------------------

  const loading = attributesLoading || participantsLoading || !selectedUser;

  if (loading) {
    return <Loading />;
  }

  const findAndSetSelectedUser = (u) => () => {
    const idx = findIndex(fields, (field) => field.id === u.id);
    if (idx !== -1) {
      const usr = getValues()?.users?.[idx];
      dispatch(setSelectedUser(usr));
    }
  };

  const userName = !isEmpty(selectedUser) ? selectedUser.firstName ?? selectedUser.email : null;
  const userDisplay = userName ? `${userName}'s` : "";
  return (
    <div className="d-flex row">
      <ResponsiveVerticalContainer className="col-md-4 mb-sm-3 pt-0">
        <UserList
            users={fields}
            orgUsers={[]}
            findAndSetSelectedUser={findAndSetSelectedUser}
            projectId={projectData?.id}
            queries={{ refetchOrgUsers: null, refetchProjectUsers: null }}
            parentForm={{ getValues, remove }}
            teamContext={teamContext}
        />
      </ResponsiveVerticalContainer>
      <ResponsiveVerticalContainer className="col-md-8 text-center pt-1">
        <div style={{ maxHeight: 260, marginBottom: "2.5rem" }}>
          <h5 className="mb-2">{userDisplay} Attribute Values</h5>
          <AttributeList userAttributes={selectedUser?.userAttributeValues} />
        </div>
        <UserActionTable
          user={selectedUser}
          userDisplay={userDisplay}
          attributes={nonConnectionAttributes}
          updateUser={() => ({})}
          allowActions={false}
        />
      </ResponsiveVerticalContainer>
    </div>
  );
}

export default UsersInsightsView;
