import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import confirmAction from "reactstrap-confirm";
import ActionForm from "components/Forms/ActionForm";
import {
  ActionFormButton,
  ResponsiveVerticalContainer,
  ScrollBarContainer,
  StyledDataGrid,
  StyledPillButton
} from "theme/StyledComponents";
import { getParticipantTableData, userActionsColumns } from "./helper";
import { selectAccountData, selectProjectData } from "../userData/userDataSlice";
import { REJECT_ACTION } from "api/actions";
import { APPROVE_ACTION } from "api/actions";
import { useCheckActionLimit } from "hooks/application.hooks";
import { useSubmit } from "hooks/form.hooks";
import { getLimitDisplay } from "features/Actions/utils";
import { selectInstanceActions } from "app/actions.slice";

function UserActionTable({
  user,
  attributes,
  allowActions = true,
  userDisplay = "",
  handleApproveOrRejectSuccess = () => ({})
}) {
  const [showForm, setShowForm] = useState(false);
  const projectData = useSelector(selectProjectData);
  const instanceBasedActions = useSelector(selectInstanceActions);

  const tableData = getParticipantTableData(user?.actions);

  const hideForm = useCallback(() => {
    setShowForm(false);
  }, [setShowForm]);

  if (!attributes) {
    return null;
  }

  if (!tableData) {
    return null;
  }

  return (
    <>
      <div className="d-flex w-100 justify-content-center align-items-center mb-0" style={{ maxHeight: "3rem" }}>
        {!showForm && allowActions && (
          <>
            <h5 className="mb-0">Action & Auto Update Log</h5>
            <StyledPillButton
              data-testid="ADD_ACTION"
              disabled={showForm}
              onClick={() => setShowForm(true)}
              style={{ width: "9rem", marginLeft: "1rem" }}
            >
              <i className="fa fa-plus" />
              &nbsp; Log Action
            </StyledPillButton>
          </>
        )}
      </div>

      {showForm && (
        <div className="w-100 mt-0 mb-4 mt-2" style={{ height: "fit-content", maxHeight: "15%" }}>
          <ActionForm
            handleClearForm={hideForm}
            handleApproveOrRejectSuccess={handleApproveOrRejectSuccess}
            attributes={attributes ?? []}
            user={user}
            customActions={instanceBasedActions}
            projectId={projectData?.id}
          />
        </div>
      )}
      <div className="d-flex flex-column mt-0">
        <ResponsiveVerticalContainer style={{ minHeight: "10rem", maxHeight: "23rem" }}>
          <ScrollBarContainer>
            <StyledDataGrid
              sx={{
                minHeight: "100% !important",
                flex: 1
              }}
              rows={tableData}
              columns={userActionsColumns(handleApproveOrRejectSuccess, allowActions)}
              pageSize={25}
              rowsPerPage={[25]}
              rowsPerPageOptions={[25]}
            />
          </ScrollBarContainer>
        </ResponsiveVerticalContainer>
      </div>
    </>
  );
}

export function ApproveOrReject({ actionId, customActionId = "", limit, onSuccess = () => ({}) }) {
  const { selectedUser } = useSelector((state) => state.userData);
  const currentAccount = useSelector(selectAccountData);

  const [approveAction, { loading: approveLoading }] = useMutation(APPROVE_ACTION, {
    fetchPolicy: "no-cache"
  });
  const [rejectAction, { loading: rejectLoading }] = useMutation(REJECT_ACTION, {
    fetchPolicy: "no-cache"
  });

  const checkActionLimit = useCheckActionLimit();

  const submitApproveAction = useSubmit({
    mutation: approveAction,
    dataPath: APPROVE_ACTION.definitions[0].name.value,
    onSuccess,
    useVariables: true,
    useGlobalLoading: true,
    variables: {
      actionId
    }
  });

  const submitRejectAction = useSubmit({
    mutation: rejectAction,
    dataPath: REJECT_ACTION.definitions[0].name.value,
    onSuccess,
    useVariables: true,
    variables: {
      actionId,
      accountId: currentAccount?.id
    }
  });

  const handleSubmitApproval = useCallback(async () => {
    const { success: withinTimeFrame } = await checkActionLimit(customActionId, selectedUser?.id);

    if (!withinTimeFrame) {
      const shouldSubmit = await confirmAction({
        confirmText: "Yes",
        cancelText: "No",
        message: `This action is limited to ${getLimitDisplay(
          limit
        )}. Approving this action will ignore the limit. Are you sure you want to proceed?`,
        confirmColor: "btn btn-success shadow border-0",
        cancelColor: "btn btn-danger shadow border-0"
      });

      if (shouldSubmit) {
        submitApproveAction();
      }
    } else {
      submitApproveAction();
    }
  }, [checkActionLimit, customActionId, limit, selectedUser?.id, submitApproveAction]);

  return (
    <div className="approvalRow d-flex justify-content-end align-items-center">
      <div>
        <span className="d-flex justify-content-between align-items-center">
          <span
            style={{
              width: "6rem",
              display: "flex",
              justifyContent: "space-around",
              alignContent: "center",
              transform: "scale(0.9)"
            }}
          >
            <span className="me-2">
              <ActionFormButton
                disabled={approveLoading || rejectLoading}
                type="submit"
                className="btn btn-outline-success shadow border-0"
                onClick={handleSubmitApproval}
                data-testid="APPROVE_ACTION"
              >
                <i className="fa fa-check" />
              </ActionFormButton>
            </span>

            <ActionFormButton
              disabled={approveLoading || rejectLoading}
              type="button"
              className="btn border-0 shadow btn-outline-danger"
              onClick={submitRejectAction}
              buttonType="delete"
              data-testid="REJECT_ACTION"
            >
              <i className="fa fa-times" />
            </ActionFormButton>
          </span>
        </span>
      </div>
    </div>
  );
}

export default memo(UserActionTable);
