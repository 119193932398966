import React, { memo, useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { map } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

import KatilystLink from "hocs/KatilystLink";
import Loading from "../../components/Loading";
import { CustomAction } from "./CustomAction";
import { DELETE_CUSTOM_ACTION, GET_CUSTOM_ACTIONS, UPDATE_CUSTOM_ACTION_ORDER } from "../../api/actions";
import { selectAttributes } from "../Attributes/AttributesSlice";
import { ResponsiveVerticalContainer, StyledCardShadow, StyledPillButton } from "theme/StyledComponents";
import { selectProjectData } from "../userData/userDataSlice";
import { useHandleError, useHandleSuccess } from "../../hooks/api.hooks";
import { mapActionToForm } from "../../utils/form.utils";
import { Prompt } from "react-router-dom";
import messages from "../../api/messages";
import CustomActionForm from "components/Forms/CustomActionForm";
import { reduceIds } from "utils/list.utils";
import { useAttributes, useCustomActions } from "hooks/application";
import { selectCustomActions } from "../../app/actions.slice";
import { GET_ROLES } from "../../api/accounts";

function Actions() {
  const [showForm, setShowForm] = useState(false);
  const [dirtyForms, setDirtyForms] = useState(new Set());
  const projectData = useSelector(selectProjectData);
  const [updateCustomActionOrder] = useMutation(UPDATE_CUSTOM_ACTION_ORDER);
  // let customActions = useSelector(selectCustomActions);
  const { data: fetchCustomActions, refetch: refetchCustomActions, loading: customActionsLoading } = useCustomActions();
  let customActions = fetchCustomActions?.getCustomActions;
  const { data, loading } = useQuery(GET_ROLES);
  let roles = [];
  if (data) {
    roles = data.getRolesForDisplay;
  }

  const clearForm = useCallback(() => {
    setShowForm(false);
  }, []);

  const attributes = useSelector(selectAttributes);
  const { loading: attributesLoading } = useAttributes();

  const [deleteCustomAction] = useMutation(DELETE_CUSTOM_ACTION);

  const { control, getValues, reset } = useForm({
    defaultValues: {
      actions: customActions
    }
  });

  const { fields, remove, move } = useFieldArray({
    control,
    name: "actions"
  });

  useEffect(() => {
    reset({ actions: customActions });
  }, [JSON.stringify(customActions), reset]);

  const handleError = useHandleError(messages.CUSTOM_ACTION_DELETE_ERROR);
  const handleDeleteSuccess = useHandleSuccess(messages.CUSTOM_ACTION_DELETE_SUCCESS);

  const saveCustomActionOrder = async () => {
    const list = reduceIds(getValues().actions);
    await updateCustomActionOrder({
      variables: { data: { projectId: projectData?.id, list } },
      skip: !projectData?.id,
      fetchPolicy: "no-cache"
    });
    refetchCustomActions().then((res) => {
      customActions = res.data.getCustomActions;
    });
  };

  // TODO(MB) refactor to use useSubmit hook
  const handleDelete = (id, index) => async () => {
    deleteCustomAction({ variables: { id }, onError: handleError }).then((res) => {
      if (res.data.deleteCustomAction.success) {
        handleDeleteSuccess();
        remove(index);
        refetchCustomActions().then((res) => {
          customActions = res.data.getCustomActions;
        });
      } else {
        handleError();
        console.error(`%c Custom Action Delete Error: Foreign Key Constraint - ${id}`, "color: red");
      }
    });
  };

  const handleOnDragEnd = async ({ destination, source }) => {
    move(source.index, destination.index);
    await saveCustomActionOrder();
  };

  const handleCreateSuccess = async () => {
    refetchCustomActions().then((res) => {
      customActions = res.data.getCustomActions;
      // undefined was added to the list of formId's in the set on creation bc no id
      // TODO(mb) see if this needs to be implemented where dirtyForms hook is used elsewhere
      setDirtyForms((prev) => {
        const newVals = [...prev].filter((id) => !!id);
        return new Set(newVals);
      });
      setShowForm(false);
    });
  };

  if (customActionsLoading) {
    return <Loading />;
  }

  if (!attributes?.length && !attributesLoading) {
    return (
      <h5 style={{ margin: "5rem auto", width: "100%", textAlign: "center" }}>
        Uh oh! You need to define at least one Attribute before creating a custom action. Add attributes{" "}
        <KatilystLink to="/configure/attributes">here</KatilystLink> and then come back to this page.
      </h5>
    );
  }
  return (
    <>
      <Prompt message={messages.UNSAVED_CHANGES} when={dirtyForms.size > 0} />
      <StyledPillButton
        data-testid="ADD_ACTION"
        onClick={() => setShowForm(true)}
        className="mx-auto mb-1"
        style={{ maxHeight: "2.5rem" }}
      >
        <i className="fa fa-plus" />
        &nbsp; Add Action
      </StyledPillButton>
      <ResponsiveVerticalContainer>
        <StyledCardShadow>
          {showForm && (
            <Row className="px-0 d-inline-flex justify-items-center w-100" style={{ marginBottom: "5rem" }}>
              <CustomActionForm
                handleClearForm={clearForm}
                handleDelete={clearForm}
                handleSaveSuccess={handleCreateSuccess}
                setDirtyForms={setDirtyForms}
              />
            </Row>
          )}
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="actions">
              {(provided) => (
                <span ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                  {fields.length > 0 &&
                    map(fields, (a, i) => {
                      const actionId = getValues().actions[i].id;
                      const action = {
                        ...mapActionToForm(a, roles),
                        id: actionId
                      };
                      return (
                        <Draggable draggableId={action.id} key={action.id} index={i}>
                          {(prv) => (
                            <span
                              ref={prv.innerRef}
                              {...prv.draggableProps}
                              {...prv.dragHandleProps}
                              className="action"
                            >
                              <span
                                key={action.id}
                                className="mb-3 w-100"
                                style={{ minWidth: 420 }}
                                data-testid={`DROPPABLE_ACTION_CARD_${action.name}`}
                              >
                                <CustomAction action={action} handleDelete={handleDelete(actionId, i)} />
                              </span>
                            </span>
                          )}
                        </Draggable>
                      );
                    })}
                </span>
              )}
            </Droppable>
          </DragDropContext>
        </StyledCardShadow>
      </ResponsiveVerticalContainer>
    </>
  );
}

export default memo(Actions);
