import React, { useState } from "react";
import { Card, Collapse, UncontrolledTooltip } from "reactstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { map } from "lodash";
import { useMutation } from "@apollo/client";

import { StyledCardHeader } from "features/Attributes/Attributes.style";
import { ActionFormButton } from "theme/StyledComponents";
import { DELETE_RULE, SAVE_RULE } from "api/rules";
import VerticalGripIcon from "../../assets/vertical-grip-icon.js";

import WhenConditionBlock from "features/Rules/RuleConditionBlock/WhenConditionBlock";
import ThenConditionBlock from "features/Rules/RuleConditionBlock/ThenConditionBlock";
import { CONDITION_TYPES } from "features/Rules/utils.js";
import DeleteRow from "components/DeleteRow/DeleteRow";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { useShouldResetForm, useSubmit } from "hooks/form.hooks";
import { usePreviousValue } from "hooks/api.hooks";
import { ruleFromFormData } from "utils/form.utils";
import messages from "../../api/messages";
import { useHasDirtyForm } from "../../hooks/form.hooks";
import CustomCheckbox from "../CustomCheckbox";
import { useSelector } from "react-redux";
import { selectSpecificOperators } from "../../app/operators.slice";

const RuleForm = ({ rule, index, attributes, handleDelete, setDirtyForms, parentForm, onRuleSaveSuccess, ruleId }) => {
  const modifierOperators = useSelector(selectSpecificOperators(["NONE", "+", "-", "*", "/"]));
  const [open, setOpen] = useState(index === 0);
  const toggleRuleOpen = () => setOpen(!open);
  const previousRule = usePreviousValue(rule);

  const [saveRule] = useMutation(SAVE_RULE);
  const [deleteRule] = useMutation(DELETE_RULE);

  const ruleForm = useForm({
    mode: "onChange",
    defaultValues: {
      ...rule,
      ruleConditions: rule.ruleConditions,
      sendNotifications: rule?.sendNotifications ?? true
    }
  });

  const fieldArray = useFieldArray({
    control: ruleForm.control, // control props comes from useForm (optional: if you are using FormContext)
    name: "ruleConditions" // unique name for your Field Array
  });

  const formUtils = {
    ...ruleForm,
    ...fieldArray
  };

  const sendNotifications = ruleForm.watch("sendNotifications");

  const onSuccess = async (updatedRule) => {
    setDirtyForms((prev) => {
      prev.delete(ruleId);
      return new Set(prev);
    });
    await onRuleSaveSuccess();
    ruleForm.reset(undefined, { keepValues: true });
  };

  const onSubmit = useSubmit({
    mutation: saveRule,
    successMessage: messages.RULE_SAVE_SUCCESS,
    errorMessage: messages.RULE_SAVE_ERROR,
    extractFormData: ruleFromFormData(index, ruleId, modifierOperators),
    onSuccess,
    dataPath: SAVE_RULE.definitions[0].name.value
  });

  const submitDelete = useSubmit({
    mutation: deleteRule,
    dataPath: DELETE_RULE.definitions[0].name.value,
    useVariables: true,
    variables: { id: ruleId },
    onSuccess: () => parentForm.remove(index),
    successMessage: messages.RULE_DELETE_SUCCESS,
    errorMessage: messages.RULE_DELETE_ERROR
  });

  useShouldResetForm(rule, previousRule, ruleForm.reset);
  useHasDirtyForm(Object.keys(ruleForm.formState.dirtyFields).length > 0, ruleId, setDirtyForms);

  return (
    <Card key={rule.id} className="my-3">
      <StyledCardHeader
        style={{
          height: "4.5rem",
          background: "#f7f9fa",
          borderBottom: "1px solid #d6d6d6",
          borderRadiusTop: "10px"
        }}
      >
        <div className="pt-auto h-100 d-flex align-items-center">
          <div className="d-flex justify-content-between align-items-center me-3">
            <div className="ps-2 ms-1 me-3">
              <VerticalGripIcon
                style={{
                  transform: "scale(2.2)"
                }}
              />
            </div>
            <UncontrolledInput
              formRegister={ruleForm.register}
              name="name"
              labelText="RULE NAME"
              className={ruleForm?.formState?.errors?.name?.message ? "error" : ""}
              validation={{ required: "Required" }}
            />
          </div>
          <UncontrolledTooltip hideArrow placement="top" target="sendNotificationsTooltip">
            When checked, this will send a notification to the user when the rule condition passes, if appropriate for
            the rule, and if they have a Role that receives notifications.
          </UncontrolledTooltip>
          <div className="d-flex flex-row justify-content-around m-auto">
            <CustomCheckbox
              testId="RULE_FORM_SEND_RULE_TRIGGER_NOTIFICATIONS"
              checked={sendNotifications ?? true}
              name={"sendNotifications"}
              formRegister={ruleForm.register}
            />
            <div id="sendNotificationsTooltip" className="ms-2 m-auto">
              {" "}
              Send Notifications
            </div>
          </div>
          <div className="d-flex justify-content-start pb-2 flex-grow-1" onClick={toggleRuleOpen}>
            <div
              className="font-weight-bold ms-5"
              style={{
                textAlign: "left",
                color: "#78909C",
                transform: "scale(2.5)"
              }}
            >
              <i className={`fa ${!open ? "fa-angle-right" : "fa-angle-down"}`} />
            </div>
          </div>

          <div className="d-flex justify-content-between" style={{ minWidth: "10rem" }}>
            <ActionFormButton
              data-testid="SAVE_RULE"
              type="button"
              onClick={ruleForm.handleSubmit(onSubmit)}
              className="btn btn-outline-success shadow border-0"
              disabled={Object.keys(ruleForm.formState.dirtyFields).length === 0}
            >
              <i className="fa fa-check" />
            </ActionFormButton>

            <ActionFormButton
              type="button"
              className="btn shadow border-0 btn-outline-primary"
              buttonType="undo"
              onClick={() => ruleForm.reset()}
              disabled={Object.keys(ruleForm.formState.dirtyFields).length === 0}
            >
              <i className="fa fa-undo" />
            </ActionFormButton>

            <DeleteRow
              handleDelete={() => handleDelete(rule, index, submitDelete)}
              confirmText={messages.RULE_DELETE_CONFIRM}
              shouldUseModal={Object.hasOwn(rule, "createdAt")}
              testId={`DELETE-${rule?.name}`}
            />
          </div>
        </div>
      </StyledCardHeader>
      <Collapse style={{ padding: "1.5rem 1.25rem 1rem" }} isOpen={open}>
        {map(fieldArray.fields, (condition, i) => {
          if (condition.type === CONDITION_TYPES.WHEN) {
            return (
              <WhenConditionBlock
                key={condition.id}
                attributes={attributes}
                ruleCondition={condition}
                rule={rule}
                index={i}
                formUtils={formUtils}
              />
            );
          }
        })}

        {map(fieldArray.fields, (condition, i) => {
          if (condition.type === CONDITION_TYPES.THEN) {
            return (
              <span key={condition.id}>
                <hr
                  style={{
                    marginBottom: "2rem",
                    width: "100%",
                    outline: "0.5px solid"
                  }}
                />
                <ThenConditionBlock
                  attributes={attributes}
                  ruleCondition={condition}
                  rule={rule}
                  index={i}
                  formUtils={formUtils}
                  modifierOperators={modifierOperators}
                />
              </span>
            );
          }
        })}
      </Collapse>
    </Card>
  );
};

export default RuleForm;
